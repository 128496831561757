<template>
  <div class="">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="Title"
        prop="title"
      >
        <el-input v-model="ruleForm.title" />
      </el-form-item>
      <el-form-item
        label="Body"
        prop="body"
      >
        <el-input
          v-model="ruleForm.body"
          type="textarea"
          rows="6"
        />
      </el-form-item>
      <el-form-item
        label="All Users"
        prop="bulk"
      >
        <el-switch
          v-model="ruleForm.bulk"
          active-color="#13ce66"
          inactive-color="#ff4949"
        />
      </el-form-item>

      <el-form-item
        v-if="ruleForm.bulk"
        label="Filter By"
        prop="filterby"
      >
        <el-select
          v-model="ruleForm.filterby"
          placeholder="Select Filter"
        >
          <el-option
            v-for="item in filters"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="!ruleForm.bulk"
        label="Select Users"
        prop="bulk"
      >
        <el-select
          v-model="ruleForm.users"
          multiple
          filterable
          remote
          collapse-tags
          reserve-keyword
          placeholder="Type Username"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in userLists"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Send
        </el-button>
        <el-button @click="resetForm('ruleForm')">
          Reset
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { SEARCH_USERS, SEND_NOTIFICATION } from '@core/services/api'

export default {
  data() {
    return {
      ruleForm: {
        title: '',
        body: '',
        bulk: false,
        filterby: 'all',
        users: [],
      },
      filters: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'paid',
          label: 'Paid',
        },
        {
          value: 'unpaid',
          label: 'Unpaid',
        },
      ],
      rules: {
        title: [
          { required: true, message: 'Please input Title', trigger: 'blur' },
          { max: 150, message: 'Length should be 150 char', trigger: 'blur' },
        ],
        body: [
          { required: true, message: 'Please input Body', trigger: 'blur' },
          { max: 500, message: 'Length should be 500 char', trigger: 'blur' },
        ],
      },
      userLists: [],
      loading: false,
      isp_id: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.ruleForm.isp_id = this.isp_id.id
          this.loading = true
          this.$http
            .post(process.env.VUE_APP_BASEURL + SEND_NOTIFICATION, this.ruleForm)
            .then(res => {
              this.loading = false
              this.$notify({
                type: 'success',
                message: res.data.messages,
              })
              this.resetForm(formName)
            }).catch(err => {
              console.log('err', err)
              this.loading = false
              this.$notify({
                type: 'error',
                message: err,
              })
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    remoteMethod(query) {
      if (query !== '' && query.length > 3) {
        this.loading = true
        this.$http
          .get(process.env.VUE_APP_BASEURL + SEARCH_USERS, {
            params: {
              search_query: query,
              isp_id: this.isp_id.id,
            },
          })
          .then(res => {
            console.log('data:', res.data.data.users)
            this.userLists = res.data.data.users
            this.loading = false
          }).finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
